/* eslint-disable no-use-before-define, no-undef, no-shadow, no-return-assign, no-param-reassign, array-callback-return, consistent-return */

const consentUI = () => {
	/** ************************************************
	 * MANAGER
	 ************************************************* */

	let manager = false;

	const setManager = (m) => {
		manager = m;
		return manager;
	};

	/** ************************************************
	 * BANNERS
	 ************************************************* */

	const createBanner = () => {
		manager.acceptAll();
	};

	/** ************************************************
	 * EVENTS
	 ************************************************* */

	const requireConsent = () => createBanner({ manager });

	const o = {
		setManager,
		requireConsent,
	};

	return o;
};

export default consentUI;
