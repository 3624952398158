/* eslint-disable no-use-before-define */

/*
Services. Check https://github.com/AmauriC/tarteaucitron.js/blob/master/tarteaucitron.services.js if you need to add more services.
Then adapt the service taking https://github.com/zemax/tartemeringuee/tree/master/services as an example.
*/
import gtag from "../../lib/cookies/gtm";
import consentUI from "../../lib/cookies/consent-ui";
import consentManager from "../../lib/cookies/consent-manager";

const Cookies = () => {
	let consent = null;

	/* eslint-disable */
	if (typeof googleTagManagerId !== "undefined" && googleTagManagerId !== "") {
		consent = consentManager();
		consent
			.register(gtag(googleTagManagerId))
			.setUI(
				consentUI({
					getText: "",
				})
			)
			.launch();
	}

	return consent;
};

export default Cookies;
