import "./scss/style.scss";
import Cookies from "./js/template-parts/popups/cookies";
// import Barba from "./js/lib/barba";
// import ChildModulesLoader from "./js/modules-loader";

// Cookies banner
window.cookies = Cookies();

document.addEventListener("DOMContentLoaded", () => {
	// BarbaJS
	// window.childBarba = new Barba();
});

window.addEventListener("load", () => {
	// Modules loader
	// window.childModulesLoader = new ChildModulesLoader();
	// window.childModulesLoader.loadModules();
});
